const initState =  [];

const productReducer = (state = initState, action) => {
  if (action.type === "FETCH_PRODUCTS_SUCCESS") {

    return action.payload;
  } 

  return state;
};

export default productReducer;
