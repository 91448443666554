
import { client } from '../../client'


export const fetchProducts = async (dispatch, getState) => {


  const response = await client.get('https://45.9.191.189/products');

  let arrayProducts = [];

  for (let item in response) {

    let newproduct = {
      id: response[item].id,
      name: response[item].nom,
      price: response[item].preu,
      discount: parseInt(response[item].descompte),
      rating: parseInt(response[item].puntuacio),
      saleCount: parseInt(response[item].numVendes),
      new: response[item].nouProducte === '1',
      category: [],
      tag: [],
      stock: response[item].estoc,
      image: [],
      shortDescription: response[item].descripcioCurta,
      fullDescription: response[item].descripcioLlarga,

    }

    newproduct.image.push(response[item].imatge);
    newproduct.category = response[item].categoria;
    newproduct.tag = response[item].tipus;

    arrayProducts.push(newproduct);
  }


  dispatch({ type: "FETCH_PRODUCTS_SUCCESS", payload: arrayProducts })



};


